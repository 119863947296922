<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="방지시설별 부품현황"
      tableId="processManual"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :merge="grid.merge"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        {{ props.row[col.name] }}
        <template v-if="col.name === 'cleanNextDt' && props.row.cleanDiff >= 1 && props.row.cleanDiff <= 7">
          <q-badge push color="red">
            D-{{props.row.cleanDiff}}
          </q-badge>
        </template>
        <template v-else-if="col.name === 'changeNextDt' && props.row.changeDiff >= 1 && props.row.changeDiff <= 7">
          <q-badge push color="red">
            D-{{props.row.changeDiff}}
          </q-badge>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'waste-report-manage',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'plantCd' },
          { index: 1, colName: 'envAirMstOutletId' },
          { index: 2, colName: 'envAirMstOutletPartType' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width: 150px',
            sortable: true,
          },
          {
            name: 'envAirMstOutletName',
            field: 'envAirMstOutletName',
            label: '방지시설',
            align: 'center',
            type: 'link',
            sortable: true,
          },
          {
            name: 'envAirMstOutletPartTypeName',
            field: 'envAirMstOutletPartTypeName',
            label: '부품타입',
            align: 'center',
            sortable: true,
          },
          {
            name: 'envAirMstOutletPartName',
            field: 'envAirMstOutletPartName',
            label: '부품명',
            align: 'center',
            type: 'link',
            sortable: true,
          },
          {
            label: '청소',
            align: 'center',
            child: [
              {
                name: 'cleanCycle',
                field: 'cleanCycle',
                label: '청소주기',
                align: 'center',
                sortable: true,
              },
              {
                name: 'cleanDt',
                field: 'cleanDt',
                label: '최근청소일',
                align: 'center',
                sortable: true,
              },
              {
                name: 'cleanNextDt',
                field: 'cleanNextDt',
                label: '다음청소예정일',
                align: 'center',
                sortable: true,
                type: 'custom'
              },
            ]
          },
          {
            label: '교체',
            align: 'center',
            child: [
              {
                name: 'changeCycle',
                field: 'changeCycle',
                label: '교체주기',
                align: 'center',
                sortable: true,
              },
              {
                name: 'changeDt',
                field: 'changeDt',
                label: '최근교체일',
                align: 'center',
                sortable: true,
              },
              {
                name: 'changeNextDt',
                field: 'changeNextDt',
                label: '다음교체예정일',
                align: 'center',
                sortable: true,
                type: 'custom'
              },
            ]
          },
          {
            name: 'sizeName',
            field: 'sizeName',
            label: '사이즈',
            align: 'center',
            sortable: true,
          },
          {
            name: 'amount',
            field: 'amount',
            label: 'LBLAMOUNT',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '50%',
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.env.air.mst.outlet.part.status;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row, col) {
      let tabname = '';
      if (col.name == 'envAirMstOutletName') {
        tabname = 'govScheduleInfo';
      } else if (col.name == 'envAirMstOutletPartName') {
        tabname = 'govScheduleHistory';
      }
      this.popupOptions.title = '대기 배출구(방지시설) 상세';
      this.popupOptions.param = {
        envAirMstPreventiveId: row.envAirMstOutletId,
        tabName: tabname,
      };
      this.popupOptions.target = () => import(`${"./airMaster03Detail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
